import { USERS_FETCH, USERS_FAILED, USERS_LOADING, UPDATE_USERS, DELETE_USERS } from '../actions/usersActions'

export const initialUsersState = { loading: true, data: [], error: null }

export function usersReducer (state = initialUsersState, action) {
  switch (action.type) {
    case USERS_LOADING:
      return { ...state, loading: true }
    case USERS_FETCH:
      return { ...state, loading: false, data: action.payload.data }
    case USERS_FAILED:
      return { ...state, loading: false, data: [], error: action.payload }
    case UPDATE_USERS:
      return {
        ...state,
        data: state.data.map(user =>
          user._id === action.payload._id ? { ...user, ...action.payload } : user
        )
      }
    case DELETE_USERS:
      return {
        ...state,
        data: state.data.filter(item => item._id !== action.payload)
      }
    default:
      return state
  }
}
