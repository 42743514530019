import React, { useCallback, useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import PropTypes from 'prop-types'
import { IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import CryptoJS from 'crypto-js'

function Dropzone ({ className, maxFiles, allowMultiple, onFilesChange, existingLogoUrl }) {
  const [files, setFiles] = useState([])
  const [rejected, setRejected] = useState([])

  useEffect(() => {
    if (Array.isArray(existingLogoUrl)) {
      const existingFiles = existingLogoUrl.map(url => ({ name: `Existing: ${url.substring(url.lastIndexOf('/') + 1)}`, preview: url }))
      setFiles(existingFiles)
    } else if (typeof existingLogoUrl === 'string' && existingLogoUrl) {
      setFiles([{ name: 'Existing: ', preview: existingLogoUrl }])
    }
  }, [existingLogoUrl])

  function generateHashedFilename (file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onloadend = () => {
        try {
          const arrayBuffer = reader.result
          const wordArray = CryptoJS.lib.WordArray.create(arrayBuffer)
          const hash = CryptoJS.MD5(wordArray)
          const hashHex = hash.toString(CryptoJS.enc.Hex)
          const extension = file.name.slice(file.name.lastIndexOf('.'))
          resolve(`${hashHex}${extension}`)
        } catch (error) {
          reject(error)
        }
      }

      reader.onerror = () => reject(new Error('Failed to read file'))
      reader.readAsArrayBuffer(file)
    })
  }

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    Promise.all(acceptedFiles.map(file =>
      generateHashedFilename(file).then(hashedFilename => ({
        preview: URL.createObjectURL(file),
        hashedName: hashedFilename,
        file
      }))
    )).then(mappedFiles => {
      if (allowMultiple) {
        const updatedFiles = [...files, ...mappedFiles]
        setFiles(updatedFiles)
        onFilesChange(updatedFiles)
      } else {
        setFiles(mappedFiles.slice(0, 1))
        onFilesChange(mappedFiles.slice(0, 1))
      }
    }).catch(error => {
      console.error('Error processing files', error)
    })

    if (rejectedFiles?.length) {
      setRejected(prevRejected => [...prevRejected, ...rejectedFiles])
    }
  }, [files, allowMultiple, onFilesChange, setFiles, setRejected])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.png']
    },
    maxFiles,
    maxSize: 1024 * 1024 * 10 // 10MB
  })

  const removeFile = (fileToRemove) => {
    const updatedFiles = files.filter(file => file !== fileToRemove)
    setFiles(updatedFiles)
    onFilesChange(updatedFiles)
  }
  const removeRejected = (name) => {
    setRejected(files => files.filter(({ file }) => file.name !== name))
  }
  return (
    <form>
        <div {...getRootProps({
          className
        })}>
        <input {...getInputProps()} />
        {
            isDragActive
              ? <p>Drop the files here ...</p>
              : <p>Drag and drop some files here, or click to select files</p>
        }
        </div>
        <Typography variant='h6' sx={{ fontSize: '16px', marginLeft: '10px', marginTop: '10px', fontWeight: 400 }}>Accepted Files</Typography>
        <ul>
            {files.map(file => (
              <li key={file.hashedName}>
                    <img src={file.preview} alt={file.hashedName} width={100} onLoad={() => {
                      URL.revokeObjectURL(file.preview)
                    }}/>
                     <IconButton onClick={() => removeFile(file)}>
                    <CloseIcon/>
                </IconButton>
                <p>{file.file?.name || file.name}</p>
                </li>
            ))}
        </ul>
        <Typography variant='h6' sx={{ fontSize: '16px', marginLeft: '10px', fontWeight: 400 }}>Rejected Files</Typography>
        <ul>
            {rejected.map(({ file, errors }) => (
                <li key={file.name}>
                    <div>
                        <p>{file.name}</p>
                        <ul>{errors.map(error => (
                            <li key={error.code}>{error.message}</li>
                        ))}
                        </ul>
                    </div>
                    <button type='button' onClick={() => removeRejected(file.name)}>
                        Remove
                    </button>
                </li>
            ))}
        </ul>
    </form>
  )
}

Dropzone.propTypes = {
  className: PropTypes.string,
  maxFiles: PropTypes.number,
  allowMultiple: PropTypes.bool,
  onFilesChange: PropTypes.func.isRequired,
  existingLogoUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
}

export default Dropzone
