import { useCallback, useContext } from 'react'
import {
  itemsSuccessFetch, itemsFailedFetch, itemsLodingFetch, itemsAdd, itemsDelete, itemsUpdate
} from '../actions/itemsActions'
import { AppContext } from '../context/AppProvider'

export const useItems = () => {
  const { dispatch } = useContext(AppContext)

  const apiUrl = 'https://private-api.primusvest.ro/v1/items'

  const searchItems = useCallback(async (searchTerm = '') => {
    const token = localStorage.getItem('token')
    let headers = { 'Content-Type': 'application/json' }
    if (token) {
      headers = { ...headers, Authorization: `${token}` }

      try {
        dispatch(itemsLodingFetch())
        const response = await fetch(`${apiUrl}?search=${searchTerm}`, {
          headers: { ...headers, Authorization: `${token}` }
        })
        const data = await response.json()
        dispatch(itemsSuccessFetch(data))
        return data.data
      } catch (error) {
        dispatch(itemsFailedFetch(error))
        console.error('Failed to fetch items:', error)
        return []
      }
    }
  }, [apiUrl])

  const exportItems = useCallback(async (filters = {}) => {
    const token = localStorage.getItem('token')
    let headers = { 'Content-Type': 'application/json' }
    if (token) {
      headers = { ...headers, Authorization: `${token}` }

      try {
        const response = await fetch(`${apiUrl}/export`, {
          method: 'POST',
          headers: { ...headers, Authorization: `${token}` },
          body: JSON.stringify({ data: filters })
        })
        const { url } = await response.json()
        // console.log(resp)
        return url
        // return data.data
      } catch (error) {
        // dispatch(itemsFailedFetch(error))
        console.error('Failed to fetch items:', error)
        return []
      }
    }
  }, [apiUrl])

  const fetchItemsByEntityType = useCallback(async (queryParam, value, filters) => {
    const token = localStorage.getItem('token')
    let headers = { 'Content-Type': 'application/json' }
    if (token) {
      headers = { ...headers, Authorization: `${token}` }
      let url = apiUrl
      let first = true
      if (queryParam !== null && value !== null) {
        first = false
        url = `${apiUrl}?${queryParam}=${value}`
      }

      if (filters && typeof filters === 'object' && Object.keys(filters).length > 0) {
        Object.entries(filters).forEach(([key, value]) => {
          url += first
            ? '?' + `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
            : '&' + `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
          first = false
        })
      }
      dispatch(itemsLodingFetch())
      try {
        const response = await fetch(`${url}`, {
          headers: { ...headers, Authorization: `${token}` }
        })
        const data = await response.json()
        dispatch(itemsSuccessFetch(data))
      } catch (error) {
        console.error('Failed to fetch items:', error)
        dispatch(itemsFailedFetch(error))
      }
    }
  }, [])

  const addItems = useCallback(async (payload) => {
    const token = localStorage.getItem('token')
    let headers = { 'Content-Type': 'application/json' }
    if (token) {
      headers = { ...headers, Authorization: `${token}` }
      try {
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers,
          body: JSON.stringify({ data: [payload] })
        })
        console.log(response.ok)
        if (response.ok) {
          const { data } = await response.json()
          console.log(data)
          dispatch(itemsAdd(data[0]))
        } else {
          console.error('Error with status code:', response.status)
        }
      } catch (error) {
        console.error('Login error:', error)
      }
    }
  }, [])

  const updateItems = useCallback(async (payload) => {
    const token = localStorage.getItem('token')
    let headers = { 'Content-Type': 'application/json' }
    if (token) {
      headers = { ...headers, Authorization: `${token}` }
      try {
        const response = await fetch(apiUrl, {
          method: 'PUT',
          headers,
          body: JSON.stringify({ data: [payload] })
        })
        if (response.ok) {
          const { data } = await response.json()
          console.log(data)
          dispatch(itemsUpdate(data[0]))
        } else {
          console.error('Error with status code:', response.status)
        }
      } catch (error) {
        console.error('Login error:', error)
      }
    }
  }, [])
  const deleteItems = async (items) => {
    const token = localStorage.getItem('token')
    let headers = { 'Content-Type': 'application/json' }
    if (token) {
      headers = { ...headers, Authorization: `${token}` }
      try {
        const response = await fetch(apiUrl, {
          method: 'DELETE',
          headers,
          body: JSON.stringify({ data: [items._id] })
        })
        if (response.ok) {
          const { data } = await response.json()
          dispatch(itemsDelete(items._id))
          console.log(data)
        } else {
          console.error('Error with status code:', response.status)
        }
      } catch (error) {
        console.error('Login error:', error)
      }
    }
  }
  return { fetchItemsByEntityType, addItems, updateItems, deleteItems, searchItems, exportItems }
}
