import React, { useCallback, useState, forwardRef, useImperativeHandle } from 'react'
import { useDropzone } from 'react-dropzone'
import { Box, Button, Typography } from '@mui/material'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import CryptoJS from 'crypto-js'
import PropTypes from 'prop-types'

const DocumentUploaderDropzone = forwardRef(({ onFilesChange }, ref) => {
  const [files, setFiles] = useState([])
  const [rejected, setRejected] = useState([])

  useImperativeHandle(ref, () => ({
    resetFiles: () => {
      setFiles([])
      setRejected([])
      onFilesChange([])
    }
  }))

  function generateHashedFilename (file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onloadend = () => {
        try {
          const arrayBuffer = reader.result
          const wordArray = CryptoJS.lib.WordArray.create(arrayBuffer)
          const hash = CryptoJS.MD5(wordArray)
          const hashHex = hash.toString(CryptoJS.enc.Hex)
          const extension = file.name.slice(file.name.lastIndexOf('.'))
          resolve(`${hashHex}${extension}`)
        } catch (error) {
          reject(error)
        }
      }

      reader.onerror = () => reject(new Error('Failed to read file'))
      reader.readAsArrayBuffer(file)
    })
  }

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    Promise.all(acceptedFiles.map(file =>
      generateHashedFilename(file).then(hashedFilename => ({
        preview: URL.createObjectURL(file),
        hashedName: hashedFilename,
        file
      }))
    )).then(mappedFiles => {
      setFiles(mappedFiles)
      onFilesChange(mappedFiles)
    }).catch(error => {
      console.error('Error processing files', error)
    })
    // setFiles(mappedFiles)
    // onFilesChange(mappedFiles)

    if (rejectedFiles?.length) {
      setRejected(prevRejected => [...prevRejected, ...rejectedFiles])
    }
  }, [onFilesChange])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
    },
    maxFiles: 1,
    maxSize: 1024 * 1024 * 10 // 10MB
  })

  return (
    <Box
      {...getRootProps()}
      sx={{
        border: '2px dashed #ccc',
        borderRadius: '8px',
        padding: '20px',
        textAlign: 'center',
        backgroundColor: '#f9f9f9',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '400px',
        width: '100%',
        margin: '0 auto'
      }}
    >
      <input {...getInputProps()} />
      {
        isDragActive
          ? <Typography variant="h6">Drop the files here...</Typography>
          : <>
            <UploadFileIcon sx={{ fontSize: 60, color: '#1976d2' }} />
            <Typography variant="h6" sx={{ marginTop: '10px' }}>Sincronizează-ți produsele cu un click</Typography>
            <Button
              variant="contained"
              sx={{ marginTop: '20px' }}
              startIcon={<UploadFileIcon />}
            >
              Upload files
            </Button>
            <Typography variant="body2" sx={{ marginTop: '10px', color: '#888' }}>
              or just drag and drop .xlsx files here
            </Typography>
          </>
      }
      {files.length > 0 && (
        <Box sx={{ marginTop: '20px' }}>
          <Typography variant="h6">Accepted Files</Typography>
          <ul>
            {files.map(file => (
              <li key={file.hashedName}>{file.hashedName}</li>
            ))}
          </ul>
        </Box>
      )}
      {rejected.length > 0 && (
        <Box sx={{ marginTop: '20px' }}>
          <Typography variant="h6" color="error">Rejected Files</Typography>
          <ul>
            {rejected.map(({ file, errors }) => (
              <li key={file.hashedName}>
                <div>
                  <p>{file.hashedName}</p>
                  <ul>
                    {errors.map(error => (
                      <li key={error.code}>{error.message}</li>
                    ))}
                  </ul>
                </div>
              </li>
            ))}
          </ul>
        </Box>
      )}
    </Box>
  )
})

DocumentUploaderDropzone.displayName = 'DocumentUploaderDropzone'

DocumentUploaderDropzone.propTypes = {
  onFilesChange: PropTypes.func.isRequired
}

export default DocumentUploaderDropzone
