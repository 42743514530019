import { useContext, useCallback } from 'react'
import { AppContext } from '../context/AppProvider'
import { settingsSuccessFetch, settingsLodingFetch, settingsFailedFetch, settingsUpdate, settingsAdd, settingsDelete } from '../actions/settingsAction'

export const useSettings = () => {
  const { state, dispatch } = useContext(AppContext)

  const apiUrl = 'https://private-api.primusvest.ro/v1/settings'

  const fetchSettings = useCallback(async () => {
    const token = localStorage.getItem('token')
    let headers = { 'Content-Type': 'application/json' }
    if (token) {
      headers = { ...headers, Authorization: `${token}` }
      dispatch(settingsLodingFetch())
      try {
        const response = await fetch(apiUrl, {
          headers: { ...headers, Authorization: `${token}` }
        })
        if (response.ok) {
          const data = await response.json()
          dispatch(settingsSuccessFetch(data))
        } else {
          console.error('Error with status code:', response.status)
          dispatch(settingsFailedFetch('Something went wrong'))
        }
      } catch (error) {
        console.error('Failed to fetch brands:', error)
        dispatch(settingsFailedFetch(error))
      }
    }
  }, [])

  const updateSettings = useCallback(async (payload) => {
    const token = localStorage.getItem('token')
    let headers = { 'Content-Type': 'application/json' }
    if (token) {
      headers = { ...headers, Authorization: `${token}` }
      try {
        const response = await fetch(apiUrl, {
          method: 'PUT',
          headers,
          body: JSON.stringify({ data: [payload] })
        })
        if (response.ok) {
          const { data } = await response.json()
          dispatch(settingsUpdate(data[0]))
        } else {
          console.error('Error with status code:', response.status)
        }
      } catch (error) {
        console.error('Login error:', error)
      }
    }
  }, [])

  const addSettings = useCallback(async (payload) => {
    const token = localStorage.getItem('token')
    let headers = { 'Content-Type': 'application/json' }
    if (token) {
      headers = { ...headers, Authorization: `${token}` }
      try {
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers,
          body: JSON.stringify({ data: [payload] })
        })
        console.log(response.ok)
        if (response.ok) {
          const { data } = await response.json()
          dispatch(settingsAdd(data[0]))
        } else {
          console.error('Error with status code:', response.status)
        }
      } catch (error) {
        console.error('Login error:', error)
      }
    }
  }, [])

  const deleteSettings = async (collectionId) => {
    const token = localStorage.getItem('token')
    let headers = { 'Content-Type': 'application/json' }
    if (token) {
      headers = { ...headers, Authorization: `${token}` }
      try {
        const response = await fetch(apiUrl, {
          method: 'DELETE',
          headers,
          body: JSON.stringify({ data: [collectionId._id] })
        })
        if (response.ok) {
          const { data } = await response.json()
          dispatch(settingsDelete(collectionId))
          console.log(data)
        } else {
          console.error('Error with status code:', response.status)
        }
      } catch (error) {
        console.error('Login error:', error)
      }
    }
  }
  return { state, fetchSettings, updateSettings, addSettings, deleteSettings }
}
