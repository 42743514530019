import React, { useState, useRef } from 'react'
import {
  Typography, Box, Button, LinearProgress
} from '@mui/material'
import DocumentUploaderDropzone from './DocumentUpload/Dropzone'
import { useS3 } from '../hooks/useS3'
import { styled } from '@mui/system'
import { useSync } from '../hooks/useSync'

const ProgressWithLabel = styled(LinearProgress)(({ theme, error }) => ({
  height: '15px',
  width: '100%',
  backgroundColor: error ? theme.palette.error.main : theme.palette.primary.main,
  '& .MuiLinearProgress-bar': {
    backgroundColor: error ? theme.palette.error.main : theme.palette.primary.dark
  }
}))

const SyncProducts = () => {
  const { uploadToS3 } = useS3()
  const { addSync } = useSync()

  const [fileForUpload, setFileForUpload] = useState(null)
  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState(0)
  const [result, setResult] = useState(null)
  const [error, setError] = useState(false)
  const [apiMessages, setApiMessages] = useState([])

  const dropzoneRef = useRef()

  const handleSubmit = async () => {
    setLoading(true)
    setError(false)
    setResult(null)
    setProgress(0)

    if (fileForUpload) {
      try {
        await uploadToS3(fileForUpload)
      } catch (error) {
        console.error('File upload failed:', error)
        // Handle the upload error appropriately
      }
    }
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 95) {
          clearInterval(timer)
          return oldProgress
        }
        const diff = Math.random() * 5
        return Math.min(oldProgress + diff, 95)
      })
    }, 500)

    setTimeout(() => {
      clearInterval(timer)
      addSync(fileForUpload).then((resp) => {
        if (!resp.errors) {
          setProgress(100)
          setResult('Sincronizare reușită!')
          setFileForUpload(null)
          // setLoading(false)
          if (resp.data && Array.isArray(resp.data)) {
            setApiMessages(resp.data) // Store the API messages
          }
          // TODO set message with resp.data is an array of strings with the output messages ["","", ""]
          // want to display the messagas that come from the api in html
          if (dropzoneRef.current) {
            dropzoneRef.current.resetFiles()
          }
        } else {
          setProgress(95)
          setError(true)
          // setLoading(false)
          setResult('Sync failed. Please try again.')
        }
      })
    }, 10000)
  }

  return (
    <>
      <Typography variant='h6' sx={{ marginBottom: '20px' }}>Sincronizare produse</Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', minHeight: '60vh' }}>
        <DocumentUploaderDropzone
          ref={dropzoneRef}
          onFilesChange={(files) => {
            console.log(files)
            setLoading(false)
            setFileForUpload(files.length ? files[0] : null)
          }}
        />
        <Button
          variant="outlined"
          color='drawer'
          onClick={handleSubmit}
          disabled={!fileForUpload || loading}
          sx={{ marginTop: '20px' }}
        >
          Sincronizare
        </Button>
        {loading && (
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', marginTop: '20px', position: 'relative' }}>
            <ProgressWithLabel variant="determinate" value={progress} error={error || undefined} />
            <Typography sx={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
              color: error ? 'error.main' : 'primary.contrastText',
              fontWeight: 'bold'
            }}>
              {error ? 'Sync failed' : progress === 100 ? 'Sincronizare reușită!' : 'Sincronizare produse...'}
            </Typography>
          </Box>
        )}
        {result && (
          <Typography sx={{ marginTop: '20px', color: result.includes('reușită') ? 'green' : 'red' }}>
            {result}
          </Typography>
        )}
         {apiMessages.length > 0 && (
          <Box sx={{ marginTop: '20px', width: '100%' }}>
            <Typography variant="h6">Status:</Typography>
            {apiMessages.map((message, index) => (
              <Typography key={index} variant="body2" sx={{ marginTop: '10px', whiteSpace: 'pre-wrap' }}>
                {message}
              </Typography>
            ))}
          </Box>
         )}
      </Box>
    </>
  )
}

export default SyncProducts
