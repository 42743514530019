import React, { useEffect } from 'react'
import { Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom'
import Dashboard from './components/Dashboard'
import Products from './components/Products'
import Settings from './components/Settings'
import SyncProducts from './components/SyncProducts'
import Login from './pages/Login'
import NotFoundPage from './pages/NotFound'
import ProtectedRoute from './components/ProtectedRoute' // Ensure this is the correct import path
import { useAppContext } from './context/AppProvider'
import Brands from './components/Brands'
import Categories from './components/Categories'
import Discounts from './components/Discounts'
import Users from './components/Users'

export default function AppRoutes () {
  const { state } = useAppContext()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (state.user.isAuthenticated && location.pathname !== '/login') {
      localStorage.setItem('lastVisitedPath', location.pathname)
    }
  }, [location, state.user.isAuthenticated])

  useEffect(() => {
    const isAuthenticated = state.user.isAuthenticated
    const lastVisitedPath = localStorage.getItem('lastVisitedPath') || '/'

    if (isAuthenticated) {
      navigate(lastVisitedPath)
    }
  }, [state.user.isAuthenticated, navigate])

  return (
    <Routes>
        <Route path="/login" element={!state.user.isAuthenticated ? <Login /> : <Navigate to="/" />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/products" element={<Products />} />
          <Route path="/brands" element={<Brands />} />
          <Route path="/categories" element={<Categories />} />
          <Route path="/discounts" element={<Discounts />} />
          <Route path="/users" element={<Users />} />
          <Route path="/sync-products" element={<SyncProducts />} />
          <Route path="/settings" element={<Settings />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
    </Routes>
  )
}
