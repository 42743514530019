import React, { useEffect } from 'react'
import AdminApp from './pages/AdminApp'
import { useCollections } from './hooks/useCollections' // Ensure this path is correct
import { useUser } from './hooks/useUser'
import { useSettings } from './hooks/useSettings'
import { useItems } from './hooks/useItems'
import { useAppContext } from './context/AppProvider'

function App () {
  const { fetchCollections } = useCollections()
  const { handleUserAuthentication, handleUserLogout, getUsers } = useUser()
  const { fetchSettings } = useSettings()
  const { fetchItemsByEntityType } = useItems()
  const { state } = useAppContext()
  const { isAuthenticated } = state.user

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (token) {
      handleUserAuthentication(token).catch(console.error)
    } else {
      handleUserLogout()
    }
  }, [])

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'token' && !event.newValue) {
        handleUserLogout()
      }
    }

    window.addEventListener('storage', handleStorageChange)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [])

  useEffect(() => {
    if (isAuthenticated) {
      fetchCollections()
      getUsers()
      fetchSettings()
      fetchItemsByEntityType(null, null, null)
    }
  }, [isAuthenticated, fetchCollections, getUsers, fetchSettings, fetchItemsByEntityType])

  return (
    <div className="App">
      <AdminApp />
    </div>
  )
}

export default App
