export const ITEMS_FETCH = 'ITEMS_FETCH'
export const ITEMS_FAILED = 'ITEMS_FAILED'
export const ITEMS_LOADING = 'ITEMS_LOADING'
export const ADD_ITEMS = 'ADD_ITEMS'
export const UPDATE_ITEMS = 'UPDATE_ITEMS'
export const DELETE_ITEMS = 'DELETE_ITEMS'

export const itemsSuccessFetch = (results) => ({
  type: ITEMS_FETCH,
  payload: results
})

export const itemsFailedFetch = (error) => ({
  type: ITEMS_FETCH,
  payload: error
})

export const itemsLodingFetch = () => ({
  type: ITEMS_LOADING
})

export const itemsAdd = (item) => ({
  type: ADD_ITEMS,
  payload: item
})

export const itemsUpdate = (item) => ({
  type: UPDATE_ITEMS,
  payload: item
})

export const itemsDelete = (_id) => ({
  type: DELETE_ITEMS,
  payload: _id
})
