import React from 'react'
import LoginForm from '../components/Forms/LoginForm'
import { Box, Typography, Stack } from '@mui/material'
import { Link } from 'react-router-dom'
import { theme } from '../styles/theme'
import Logo from '../icons/logo-negru.png'

const Login = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '90vh', bgcolor: theme.palette.primary.main }}>
      <Stack spacing={1}>
       <Link to="/" className="logo">
          <Typography variant='h6' sx={{ display: 'flex', justifyContent: 'center', marginBottom: '40px' }}>
              <img src={Logo} alt="Logo" style={{ width: '200px' }}/>
          </Typography>
      </Link>
      <LoginForm />
    </Stack>
    </Box>
  )
}

export default Login
