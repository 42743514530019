export const FETCH_COLLECTIONS = 'FETCH_COLLECTIONS'
export const FAILED_COLLECTIONS = 'FAILED_COLLECTIONS'
export const LOADING_COLLECTIONS = 'LOADING_COLLECTIONS'
export const ADD_COLLECTIONS = 'ADD_COLLECTIONS'
export const UPDATE_COLLECTIONS = 'UPDATE_COLLECTIONS'
export const DELETE_COLLECTIONS = 'DELETE_COLLECTIONS'

export const collectionsSuccessFetch = (results) => ({
  type: FETCH_COLLECTIONS,
  payload: results
})

export const collectionsFailedFetch = (error) => ({
  type: FAILED_COLLECTIONS,
  payload: error
})

export const collectionsLodingFetch = () => ({
  type: LOADING_COLLECTIONS
})

export const collectionsAdd = (item) => ({
  type: ADD_COLLECTIONS,
  payload: item
})

export const collectionsUpdate = (item) => ({
  type: UPDATE_COLLECTIONS,
  payload: item
})

export const collectionsDelete = (_id) => ({
  type: DELETE_COLLECTIONS,
  payload: _id
})
