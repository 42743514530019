import React, { useState } from 'react'
import {
  Typography, Button, IconButton,
  Table, TableBody, TableRow, TableHead, TableContainer, TableCell,
  Dialog, DialogActions, DialogContent, DialogTitle,
  Grid, TextField, FormControl, InputLabel, Select, MenuItem
} from '@mui/material'
import { Add, Edit, DeleteForever } from '@mui/icons-material'
import { Colors } from '../styles/theme'
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import './Brands.css'
import DeleteConfirmationDialog from './DeleteConfirmationDialog'
import { useAppContext } from '../context/AppProvider'
// import { useUploadToS3 } from '../hooks/useUploadToS3'
import { useCollections } from '../hooks/useCollections'

const validationData = Yup.object().shape({
  name: Yup.string().required('Name is required')

})

const Discounts = () => {
  const { state } = useAppContext()
  const discounts = state.collections.discount.data
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [brandToDelete, setBrandToDelete] = useState(null)

  const { addCollections, deleteCollections, updateCollections } = useCollections()

  const [open, setOpen] = useState(false)
  // const [discount, setDiscount] = useState('')

  const uniqueDiscountTypes = ['percent', 'fixed']
  const [initialValues, setInitialValues] = useState({
    _id: -1,
    name: '',
    type: 'discount',
    filters: ['brands', 'categories', 'price'],
    link: '',
    discount_type: '',
    value: 0
  })
  const handleAddDiscount = (product) => {
    setInitialValues({
      _id: -1,
      name: '',
      type: 'discount',
      filters: ['brands', 'categories', 'price'],
      link: '',
      discount_type: '',
      value: 0
    })
    console.log(product)
    setOpen(true)
  }

  const handleDelete = (product) => {
    console.log('delete', product)
    deleteCollections(product)
  }

  const handleOpenDeleteDialog = (user) => {
    setBrandToDelete(user)
    setDeleteDialogOpen(true)
  }

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false)
    setBrandToDelete(null)
  }

  const handleConfirmDelete = () => {
    if (brandToDelete) {
      handleDelete(brandToDelete)
    }
    handleCloseDeleteDialog()
  }
  const handleEdit = (product) => {
    setInitialValues(product)
    setOpen(true)
  }

  const handleSubmit = async (values) => {
    setOpen(false)
    values.link = values.name.split(' ').join('-').toLowerCase()
    if (values._id === -1) {
      await addCollections(values)
    } else {
      await updateCollections(values)
    }
  }
  return (
    <>
      <Typography variant='h6' sx={{ marginBottom: '20px' }}>Promoții</Typography>
      <Button startIcon={<Add />} variant='outlined' onClick={handleAddDiscount} sx={{ color: Colors.fontColor.main, borderColor: Colors.borderColor.main, marginBottom: '20px' }}>Adaugă promoție nouă</Button>
      <TableContainer>
        <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nume</TableCell>
            <TableCell>Tip promoție</TableCell>
            <TableCell>Valoare</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            {discounts.map(brand =>
            <TableRow key={brand._id}>
              {/* <TableCell>{product._id}</TableCell> */}
              <TableCell sx={{ textTransform: 'capitalize' }}>{brand.name}</TableCell>
              <TableCell>{brand.discount_type}</TableCell>
              {brand.discount_type === 'percent'
                ? <TableCell>-{brand.value}% </TableCell>
                : <TableCell>-{brand.value} RON </TableCell>
              }
              <TableCell>
                <IconButton onClick={() => handleEdit(brand)}>
                  <Edit />
                </IconButton>
                <IconButton onClick={() => handleOpenDeleteDialog(brand)}>
                  <DeleteForever sx={{ color: Colors.accent.main }}/>
                </IconButton>
              </TableCell>
            </TableRow>
            )}
        </TableBody>
        </Table>
      </TableContainer>
      <Dialog
      open={open}
      fullWidth
      maxWidth='lg'
      >
        <DialogTitle>
              {'Adaugă/modifică promoția'}
        </DialogTitle>
        <Formik initialValues={initialValues} validationSchema={validationData}
        onSubmit={handleSubmit}>
          {({ values, setFieldValue, dirty, isValid, getFieldProps }) => (
          <Form>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    name='name'
                    label='Nume'
                    fullWidth
                    required
                  />
                  <ErrorMessage name='name'>
                    {(message) => (
                      <Typography color={'red'}>{message}</Typography>
                    )}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={FormControl}
                    name='discount_type'
                    fullWidth
                    required
                  >
                    <InputLabel id="demo-simple-select-autowidth-label">Tip promoție</InputLabel>
                    <Select
                      labelId="discount-type-label"
                      id="discount_type"
                      value={values.discount_type}
                      onChange={event => setFieldValue('discount_type', event.target.value)}
                      label="Tip promoție"
        >
                      {uniqueDiscountTypes.map((discount) => (
                        <MenuItem key={discount} value={discount}>
                          {discount}
                        </MenuItem>
                      ))}
                    </Select>
                  </Field>
                  <ErrorMessage name='discount_type'>
                    {(message) => (
                      <Typography color={'red'}>{message}</Typography>
                    )}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    name='value'
                    label='Valoare'
                    fullWidth
                    required
                  />
                  <ErrorMessage name='value'>
                    {(message) => (
                      <Typography color={'red'}>{message}</Typography>
                    )}
                  </ErrorMessage>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
            {
                getFieldProps('_id').value !== -1
                  ? (
                <Button
               disabled={!dirty || !isValid}
               type='submit' variant='contained' color='primary'>Save Edit</Button>
                    )
                  : (
                <Button
               disabled={!dirty || !isValid}
               type='submit' variant='contained' color='primary'>Save</Button>
                    )
               }
              <Button autoFocus variant='contained' onClick={() => setOpen(false)}>Cancel</Button>
            </DialogActions>
          </Form>

          )}
        </Formik>
      </Dialog>
      <DeleteConfirmationDialog
      open={deleteDialogOpen}
      onClose={handleCloseDeleteDialog}
      onConfirm={handleConfirmDelete}
      object={brandToDelete ? brandToDelete.name : ''}
    />
    </>
  )
}

export default Discounts
