import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

export const LogoLoader = ({ src, alt, maxRetries = 3, retryDelay = 4000 }) => {
  const [currentSrc, setCurrentSrc] = useState(src)
  const [retries, setRetries] = useState(0)

  useEffect(() => {
    const img = new Image()
    img.onload = () => setCurrentSrc(src)
    img.onerror = () => {
      if (retries < maxRetries) {
        setTimeout(() => {
          setCurrentSrc(`${src}`)
          setRetries(retries + 1)
        }, retryDelay)
      }
    }
    img.src = src
  }, [src, retries, maxRetries, retryDelay])

  return (
    <img src={currentSrc} alt={alt} style={{ height: '30px' }} onError={(e) => { e.target.style.display = 'none' }} />
  )
}

LogoLoader.propTypes = {
  retryDelay: PropTypes.number,
  maxRetries: PropTypes.number,
  alt: PropTypes.string,
  src: PropTypes.string
}
