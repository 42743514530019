import React, { useState } from 'react'
import {
  Typography, Button, IconButton,
  Table, TableBody, TableRow, TableHead, TableContainer, TableCell,
  Dialog, DialogActions, DialogContent, DialogTitle,
  Grid, TextField
} from '@mui/material'
import { Add, Edit, DeleteForever } from '@mui/icons-material'
import { Colors } from '../styles/theme'
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import Dropzone from './ImageUpload/Dropzone'
import './Brands.css'
import DeleteConfirmationDialog from './DeleteConfirmationDialog'
import { useAppContext } from '../context/AppProvider'
import { useS3 } from '../hooks/useS3'
import { useCollections } from '../hooks/useCollections'
import { LogoLoader } from './LogoLoader'

const validationData = Yup.object().shape({
  name: Yup.string().required('Name is required')

})

const Brands = () => {
  const { state } = useAppContext()
  const brands = state.collections.brands.data
  const [fileForUpload, setFileForUpload] = useState(null)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [brandToDelete, setBrandToDelete] = useState(null)

  const { uploadToS3 } = useS3()
  const { addCollections, deleteCollections, updateCollections } = useCollections()

  const [open, setOpen] = useState(false)
  const [initialValues, setInitialValues] = useState({
    _id: -1,
    name: '',
    type: 'brands',
    filters: ['price', 'categories'],
    logo: '',
    link: '',
    sortPriority: 1
  })
  const handleAddBrand = (product) => {
    setInitialValues({
      _id: -1,
      name: '',
      type: 'brands',
      filters: ['price', 'categories'],
      logo: '',
      link: '',
      sortPriority: 1
    })
    console.log(product)
    setOpen(true)
  }

  const handleDelete = (product) => {
    console.log('delete', product)
    deleteCollections(product)
  }

  const handleOpenDeleteDialog = (user) => {
    setBrandToDelete(user)
    setDeleteDialogOpen(true)
  }

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false)
    setBrandToDelete(null)
  }

  const handleConfirmDelete = () => {
    if (brandToDelete) {
      handleDelete(brandToDelete)
    }
    handleCloseDeleteDialog()
  }
  const handleEdit = (product) => {
    setInitialValues(product)
    setOpen(true)
  }

  const handleSubmit = async (values) => {
    if (fileForUpload) {
      try {
        await uploadToS3(fileForUpload)
        // check if file is avaiable
      } catch (error) {
        console.error('File upload failed:', error)
      }
    }

    setOpen(false)
    values.link = values.name.split(' ').join('-').toLowerCase()
    if (values._id === -1) {
      await addCollections(values)
    } else {
      await updateCollections(values)
    }
  }
  return (
    <>
      <Typography variant='h6' sx={{ marginBottom: '20px' }}>Branduri</Typography>
      <Button startIcon={<Add />} variant='outlined' onClick={handleAddBrand} sx={{ color: Colors.fontColor.main, borderColor: Colors.borderColor.main, marginBottom: '20px' }}>Adaugă brand nou</Button>
      <TableContainer>
        <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nume</TableCell>
            <TableCell>Logo</TableCell>
            <TableCell>Link URL</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            {brands.map(brand =>
            <TableRow key={brand._id}>
              {/* <TableCell>{product._id}</TableCell> */}
              <TableCell sx={{ textTransform: 'capitalize' }}>{brand.name}</TableCell>
              <TableCell>{brand.logo
                ? (
                  <LogoLoader
                  src={brand.logo}
                  alt={`${brand.name} logo`}
                />
                  )
                : ('None')}</TableCell>
              <TableCell>{brand.logo ? brand.logo : 'None'}</TableCell>
              <TableCell>
                <IconButton onClick={() => handleEdit(brand)}>
                  <Edit />
                </IconButton>
                <IconButton onClick={() => handleOpenDeleteDialog(brand)}>
                  <DeleteForever sx={{ color: Colors.accent.main }}/>
                </IconButton>
              </TableCell>
            </TableRow>
            )}
        </TableBody>
        </Table>
      </TableContainer>
      <Dialog
      open={open}
      fullWidth
      maxWidth='lg'
      >
        <DialogTitle>
              {'Adaugă/modifică brandul'}
        </DialogTitle>
        <Formik initialValues={initialValues} validationSchema={validationData}
        onSubmit={handleSubmit}>
          {({ setFieldValue, dirty, isValid, getFieldProps }) => (
          <Form>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    name='name'
                    label='Brand'
                    fullWidth
                    required
                  />
                  <ErrorMessage name='name'>
                    {(message) => (
                      <Typography color={'red'}>{message}</Typography>
                    )}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={Dropzone}
                    name='logo'
                    onFilesChange={(files) => { console.log(files); setFieldValue('logo', files.length !== 0 ? files[0].hashedName : ''); setFileForUpload(files.length ? files[0] : null) }}
                    className= 'brands-dragndrop'
                    existingLogoUrl={initialValues.logo}
                  />
                  <ErrorMessage name='logo'>
                    {(message) => (
                      <Typography color={'red'}>{message}</Typography>
                    )}
                  </ErrorMessage>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
            {
                getFieldProps('_id').value !== -1
                  ? (
                <Button
               disabled={!dirty || !isValid}
               type='submit' variant='contained' color='primary'>Save Edit</Button>
                    )
                  : (
                <Button
               disabled={!dirty || !isValid}
               type='submit' variant='contained' color='primary'>Save</Button>
                    )
               }
              <Button autoFocus variant='contained' onClick={() => setOpen(false)}>Cancel</Button>
            </DialogActions>
          </Form>

          )}
        </Formik>
      </Dialog>
      <DeleteConfirmationDialog
      open={deleteDialogOpen}
      onClose={handleCloseDeleteDialog}
      onConfirm={handleConfirmDelete}
      object={brandToDelete ? brandToDelete.name : ''}
    />
    </>
  )
}

export default Brands
