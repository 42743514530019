import React, { useState } from 'react'
import { Box } from '@mui/material'
import { theme, DrawerWidth } from '../styles/theme'
import AppRoutes from '../Routes'
import { BrowserRouter as Router } from 'react-router-dom'
import NavDrawer, { DrawerHeader } from '../components/NavDrawer'
import { styled } from '@mui/material/styles'
import { useAppContext } from '../context/AppProvider'

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(1),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: `-${DrawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: 0
    })
  })
)

const AdminApp = () => {
  const [open, setOpen] = useState(true)
  const { state } = useAppContext()
  console.log(state)
  return (
      <Box sx={{
        display: 'flex',
        bgcolor: theme.palette.primary.main,
        minHeight: '100vh'
      }}>
        <Router>
          {state.user.isAuthenticated && <NavDrawer open={open} setOpen={setOpen} /> }
          <Main open={open}>
            <DrawerHeader />
            <AppRoutes />
        </Main>
        </Router>
      </Box>
  )
}

export default AdminApp
