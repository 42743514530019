import { userReducer } from './userReducer'
import { collectionsReducer } from './collectionsReducer'
import { settingsReducer } from './settingsReducer'
import { usersReducer } from './usersReducer'
import { itemsReducer } from './itemsReducer'

export const initialState = {
  user: { isAuthenticated: false, data: {}, loading: true, show: false },
  users: { loading: true, data: [], error: null },
  items: { loading: false, data: [], total: 0, error: null },
  collections: {
    brands: { loading: true, data: [], error: null },
    categories: { loading: true, data: [], error: null },
    discount: { loading: true, data: [], error: null }
  },
  settings: { loading: true, data: [], error: null }

}

export const rootReducer = ({ user, users, collections, settings, items }, action) => ({
  user: userReducer(user, action),
  collections: collectionsReducer(collections, action),
  settings: settingsReducer(settings, action),
  users: usersReducer(users, action),
  items: itemsReducer(items, action)
})
