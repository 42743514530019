import { SETTINGS_FETCH, SETTINGS_FAILED, SETTINGS_LOADING, ADD_SETTINGS, UPDATE_SETTINGS, DELETE_SETTINGS } from '../actions/settingsAction'

export const initialItemsState = { loading: true, data: [], error: null }

export const settingsReducer = (state = initialItemsState, action) => {
  switch (action.type) {
    case SETTINGS_LOADING:
      return { ...state, loading: true }
    case SETTINGS_FETCH:
      return { ...state, loading: false, data: action.payload.data }
    case SETTINGS_FAILED:
      return { ...state, loading: false, data: [], error: action.payload }
    case ADD_SETTINGS:
      return {
        ...state,
        data: [...state.data, action.payload]
      }
    case UPDATE_SETTINGS: {
      console.log(action.payload)
      const newData = state.data.map((item) =>
        item._id === action.payload._id ? action.payload : item
      )
      console.log(newData)
      return {
        ...state, data: newData
      }
    }
    case DELETE_SETTINGS: {
      const filteredData = state.data.filter((item) => item._id !== action.payload._id)
      return {
        ...state,
        data: filteredData
      }
    }
    default:
      return state
  }
}
