import { createTheme } from '@mui/material/styles'

export const DrawerWidth = 250

// colors
export const Colors = {
  black: '#000',
  primary: {
    main: '#FFFFFF'
  },
  secondary: {
    main: '#E4E4E4'
  },
  accent: {
    main: '#e20613',
    light: '#eb515a'
  },
  fontColor: {
    main: '#313131',
    medium: '#616161',
    light: '#919191',
    dark: '#1D1D1B'
  },
  borderColor: {
    main: '#31313140'
  },
  success: {
    main: '#00AA00'
  },
  transparent: {
    main: '#00000000'
  },
  background: {
    main: '#F5F5F5'
  }
}
// css utils
export const cssUtils = {
  boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px'
}
// create theme
export const theme = createTheme({
  palette: {
    primary: {
      main: '#FFFFFF'
    },
    secondary: {
      main: '#E4E4E4'
    },
    accent: {
      main: '#e20613',
      light: '#eb515a'
    },
    fontColor: {
      main: '#313131',
      medium: '#616161',
      light: '#919191',
      dark: '#1D1D1B'
    },
    borderColor: {
      main: '#31313140'
    },
    success: {
      main: '#00AA00'
    },
    drawer: {
      main: '#212252'
    }
  },

  components: {
    MuiAppBar: {
      styleOverrides: {
        root: { background: Colors.transparent }
      }
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          width: DrawerWidth,
          background: Colors.primary,
          color: Colors.secondary
        }
      }
    }
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      smallmobile: 0,
      mobile: 450,
      tablet: 640,
      laptop: 1024,
      desktop: 1200,
      smalldesktop: 1353
    }
  }
})
