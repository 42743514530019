export const SETTINGS_FETCH = 'SETTINGS_FETCH'
export const SETTINGS_FAILED = 'SETTINGS_FAILED'
export const SETTINGS_LOADING = 'SETTINGS_LOADING'
export const ADD_SETTINGS = 'ADD_SETTINGS'
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS'
export const DELETE_SETTINGS = 'DELETE_SETTINGS'

export const settingsSuccessFetch = (results) => ({
  type: SETTINGS_FETCH,
  payload: results
})

export const settingsFailedFetch = (error) => ({
  type: SETTINGS_FAILED,
  payload: error
})
export const settingsLodingFetch = () => ({
  type: SETTINGS_LOADING
})

export const settingsAdd = (item) => ({
  type: ADD_SETTINGS,
  payload: item
})

export const settingsUpdate = (item) => ({
  type: UPDATE_SETTINGS,
  payload: item
})

export const settingsDelete = (_id) => ({
  type: DELETE_SETTINGS,
  payload: _id
})
