import { FAILED_COLLECTIONS, FETCH_COLLECTIONS, LOADING_COLLECTIONS, ADD_COLLECTIONS, UPDATE_COLLECTIONS, DELETE_COLLECTIONS } from '../actions/collectiosActions'

export const initialState = {
  brands: { loading: true, data: [], error: null },
  categories: { loading: true, data: [], error: null },
  discount: { loading: true, data: [], error: null }
}

export const collectionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_COLLECTIONS:
      return initialState
    case FETCH_COLLECTIONS:
    {
      const newState = { ...state }
      action.payload.data.forEach(item => {
        const { type } = item // Assuming data structure needs adjusting
        if (!newState[type]) {
          newState[type] = { loading: false, data: [], error: null }
        }
        newState[type].data.push(item)
        newState[type].loading = false
      })
      return newState
    }
    case FAILED_COLLECTIONS:
      return { ...state, [action.payload.type]: { ...state[action.payload.type], loading: false, error: action.payload.error } }

    case ADD_COLLECTIONS:
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          data: [...state[action.payload.type].data, action.payload]
        }
      }
    case UPDATE_COLLECTIONS: {
      // console.log(state[action.payload.type])
      const newData = state[action.payload.type].data.map((item) =>
        item._id === action.payload._id ? action.payload : item
      )
      console.log(newData)
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          data: newData
        }
      }
    }
    case DELETE_COLLECTIONS: {
      const filteredData = state[action.payload.type].data.filter((item) => item._id !== action.payload._id)
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          data: filteredData
        }
      }
    }
    default:
      return state
  }
}
