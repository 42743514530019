import React, { useState } from 'react'
import { styled, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import CssBaseline from '@mui/material/CssBaseline'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import DashboardIcon from '@mui/icons-material/Dashboard'
import DatasetIcon from '@mui/icons-material/Dataset'
import CategoryIcon from '@mui/icons-material/Category'
import DiscountIcon from '@mui/icons-material/Discount'
import GroupIcon from '@mui/icons-material/Group'
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined'
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload'
import SyncIcon from '@mui/icons-material/Sync'
import LogoutIcon from '@mui/icons-material/Logout'
import PropTypes from 'prop-types'
import AppBar from './AppBar'
import Typography from '@mui/material/Typography'
import { Colors, DrawerWidth } from '../styles/theme'
import { useNavigate } from 'react-router-dom'
import { useUser } from '../hooks/useUser'

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end'
}))

const MyListItemButton = ({ selected, icon, text, handleNavbarItemClicked }) => {
  return (
      <ListItemButton
        onClick={() => handleNavbarItemClicked(text)}
        sx={{
          color: Colors.primary.main,
          ...(selected && {
            background: '#43426C',
            borderRadius: 2,
            fontWeight: 400,
            color: Colors.primary.main
          })
        }}
      >
          <ListItemIcon sx={{ color: Colors.primary.main, ...(selected && { color: Colors.primary.main }) }}>
            {icon}
          </ListItemIcon>
          <ListItemText primary={text} />
      </ListItemButton>)
}

export default function NavDrawer ({ open, setOpen }) {
  const theme = useTheme()
  const [selectedItem, setSelectedItem] = useState('')
  const { handleUserLogout } = useUser()

  const routeMappings = {
    'Setări website': 'settings',
    Utilizatori: 'users',
    Dashboard: 'dashboard',
    Branduri: 'brands',
    Categorii: 'categories',
    Promoții: 'discounts',
    Produse: 'products',
    Sincronizare: 'sync-products'

  }

  const getEntityTypeFromPath = (to) => {
    if (routeMappings[to]) {
      return routeMappings[to]
    }
    for (const path in routeMappings) {
      if (to.includes(path)) {
        return routeMappings[path]
      }
    }
    return '/'
  }
  const navigate = useNavigate()

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const handleNavbarItemClicked = (item) => {
    if (item === 'Logout') {
      handleUserLogout()
    } else {
      setSelectedItem(item)
      const navigateTo = getEntityTypeFromPath(item)
      navigate(navigateTo)
    }
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar open={open} handleDrawerOpen={handleDrawerOpen}/>
      <Drawer
        sx={{
          width: DrawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: DrawerWidth,
            bgcolor: '#212252',
            boxSizing: 'border-box'
          }
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
        {open && <Typography fontWeight={'bold'} color={Colors.primary.main} variant="h6" noWrap component="div">
            primusvest admin
          </Typography>
          }
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon sx={{ color: Colors.primary.main }} /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
            <ListItem disablePadding>
              <MyListItemButton
              text={'Dashboard'}
              icon={<DashboardIcon />}
              handleNavbarItemClicked={handleNavbarItemClicked}
              selected={selectedItem.includes('Dashboard')}
              />
            </ListItem>
            <ListItem disablePadding>
              <MyListItemButton
              text={'Utilizatori'}
              icon={<GroupIcon />}
              handleNavbarItemClicked={handleNavbarItemClicked}
              selected={selectedItem.includes('Utilizatori')}
              />
            </ListItem>
            <ListItem disablePadding>
              <MyListItemButton
              text={'Branduri'}
              icon={<DatasetIcon />}
              handleNavbarItemClicked={handleNavbarItemClicked}
              selected={selectedItem.includes('Branduri')}
              />
              </ListItem>
            <ListItem disablePadding>
              <MyListItemButton
              text={'Categorii'}
              icon={<CategoryIcon />}
              handleNavbarItemClicked={handleNavbarItemClicked}
              selected={selectedItem.includes('Categorii')}
              />
            </ListItem>
            <ListItem disablePadding>
              <MyListItemButton
              text={'Promoții'}
              icon={<DiscountIcon />}
              handleNavbarItemClicked={handleNavbarItemClicked}
              selected={selectedItem.includes('Promoții')}
              />
            </ListItem>
            <ListItem disablePadding>
              <MyListItemButton
              text={'Produse'}
              icon={<InventoryOutlinedIcon />}
              handleNavbarItemClicked={handleNavbarItemClicked}
              selected={selectedItem.includes('Produse')}
              />
            </ListItem>
            <ListItem disablePadding>
              <MyListItemButton
              text={'Setări website'}
              icon={<DriveFolderUploadIcon />}
              handleNavbarItemClicked={handleNavbarItemClicked}
              selected={selectedItem.includes('Setări website')}
              />
            </ListItem>
            <ListItem disablePadding>
              <MyListItemButton
              text={'Sincronizare'}
              icon={<SyncIcon />}
              handleNavbarItemClicked={handleNavbarItemClicked}
              selected={selectedItem.includes('Sync Produse')}
              />
            </ListItem>
            <ListItem disablePadding>
              <MyListItemButton
              text={'Logout'}
              icon={<LogoutIcon />}
              handleNavbarItemClicked={handleNavbarItemClicked}
              selected={selectedItem.includes('Logout')}
              />
            </ListItem>
        </List>

      </Drawer>
    </Box>
  )
}
NavDrawer.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func
}

MyListItemButton.propTypes = {
  selected: PropTypes.bool,
  icon: PropTypes.element,
  text: PropTypes.string,
  handleNavbarItemClicked: PropTypes.func
}
