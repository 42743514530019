export const USERS_FETCH = 'USERS_FETCH'
export const USERS_FAILED = 'USERS_FAILED'
export const USERS_LOADING = 'USERS_LOADING'
export const ADD_USERS = 'ADD_USERS'
export const UPDATE_USERS = 'UPDATE_USERS'
export const DELETE_USERS = 'DELETE_USERS'

export const usersSuccessFetch = (data) => ({
  type: USERS_FETCH,
  payload: data
})

export const usersFailedFetch = (error) => ({
  type: USERS_FETCH,
  payload: error
})
export const usersLodingFetch = () => ({
  type: USERS_LOADING
})

export const usersAdd = (item) => ({
  type: ADD_USERS,
  payload: item
})

export const usersUpdate = (item) => ({
  type: UPDATE_USERS,
  payload: item
})

export const usersDelete = (_id) => ({
  type: DELETE_USERS,
  payload: _id
})
