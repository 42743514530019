import React from 'react'
import Logo from '../icons/logo-negru.png'
import { Typography, Box } from '@mui/material'

const Dashboard = () => {
  return (
    <Box height='50vh' sx={{ display: 'flex', justifyContent: 'flex-start', padding: '50px' }}>
          <Box position='relative'>
            <Typography>
              <img src={Logo} alt='logo' style={{ width: '40vh' }} />
            </Typography>
            <Typography variant='h2' color='#43426C' position='absolute' right='0px' sx={{ fontSize: '50px' }}>admin</Typography>
          </Box>
    </Box>
  )
}

export default Dashboard
