import React from 'react'
import Toolbar from '@mui/material/Toolbar'
import { styled } from '@mui/material/styles'
import MuiAppBar from '@mui/material/AppBar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import PropTypes from 'prop-types'
import { DrawerWidth, Colors } from '../styles/theme'

const StyledAppbar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    width: `calc(100% - ${DrawerWidth}px)`,
    marginLeft: `${DrawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}))

const AppBar = ({ open, handleDrawerOpen }) => {
  return (
    <StyledAppbar position="fixed" elevation={0} open={open}>
        <Toolbar>
          <IconButton
            color={Colors.fontColor.dark}
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          {!open && <Typography fontWeight={'bold'} color={Colors.fontColor.dark} variant="h6" noWrap component="div">
            primusvest admin
          </Typography>
          }
        </Toolbar>
      </StyledAppbar>
  )
}

export default AppBar
AppBar.propTypes = {
  open: PropTypes.bool,
  handleDrawerOpen: PropTypes.func
}
