import { useContext, useCallback } from 'react'
import { AppContext } from '../context/AppProvider'

export const useSync = () => {
  const { state } = useContext(AppContext)

  const apiUrl = 'https://private-api.primusvest.ro/v1/sync'

  const addSync = useCallback(async (payload) => {
    const token = localStorage.getItem('token')
    let headers = { 'Content-Type': 'application/json' }
    if (token) {
      console.log(payload.hashedName)
      headers = { ...headers, Authorization: `${token}` }
      try {
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers,
          body: JSON.stringify({ file_key: payload.hashedName })
        })
        console.log(response.ok)
        if (response.ok) {
          const { data, errors } = await response.json()
          return { data, errors }
        } else {
          console.error('Error with status code:', response.status)
          return { data: [], errors: [response.status] }
        }
      } catch (error) {
        console.error('Login error:', error)
        return { data: [], errors: error }
      }
    }
  }, [])

  return { state, addSync }
}
