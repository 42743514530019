import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useAppContext } from '../context/AppProvider' // Adjust the path as necessary

const ProtectedRoute = () => {
  const { state } = useAppContext()
  const location = useLocation() // Capture the current location

  // If not authenticated, redirect to login and pass the current location in state
  return state.user.isAuthenticated
    ? (
    <Outlet />
      )
    : (
    <Navigate to="/login" state={{ from: location }} replace />
      )
}

export default ProtectedRoute
