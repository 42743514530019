import { useContext } from 'react'
import { AppContext } from '../context/AppProvider'
import { usersUpdate, usersDelete } from '../actions/usersActions'

export const useUsers = () => {
  const { dispatch } = useContext(AppContext)
  const apiUrl = 'https://private-api.primusvest.ro/v1/users'
  const apiApprove = 'https://private-api.primusvest.ro/v1/approve'

  const handleDeleteUser = async (userId) => {
    const token = localStorage.getItem('token')
    if (token) {
      try {
        deleteUser(token, userId)
      } catch (error) {
        console.error('Token handling failed:', error)
      }
    }
  }

  const handleUpdateUserProfile = (payload) => {
    const token = localStorage.getItem('token')
    if (token) {
      try {
        updateUserProfile(token, payload)
      } catch (error) {
        console.error('Token handling failed:', error)
      }
    }
  }

  const handleApproveUser = (payload) => {
    const token = localStorage.getItem('token')
    if (token) {
      try {
        handleApprove(token, payload)
      } catch (error) {
        console.error('Token handling failed:', error)
      }
    }
  }

  const deleteUser = async (token, userId) => {
    let headers = { 'Content-Type': 'application/json' }
    if (token) {
      headers = { ...headers, Authorization: `${token}` }
      try {
        const response = await fetch(apiUrl, {
          method: 'DELETE',
          headers,
          body: JSON.stringify({ data: [userId] })
        })
        if (response.ok) {
          const { data } = await response.json()
          dispatch(usersDelete(userId))
          console.log(data)
        } else {
          console.error('Error with status code:', response.status)
        }
      } catch (error) {
        console.error('Login error:', error)
      }
    }
  }

  const updateUserProfile = async (token, payload) => {
    let headers = { 'Content-Type': 'application/json' }
    if (token) {
      headers = { ...headers, Authorization: `${token}` }
      try {
        const response = await fetch(apiUrl, {
          method: 'PUT',
          headers,
          body: JSON.stringify({ data: [payload] })
        })
        if (response.ok) {
          const { results } = await response.json()
          dispatch(usersUpdate(payload))
          console.log(results)
        } else {
          console.error('Error with status code:', response.status)
        }
      } catch (error) {
        console.error('Login error:', error)
      }
    }
  }

  const handleApprove = async (token, payload) => {
    let headers = { 'Content-Type': 'application/json' }
    if (token) {
      headers = { ...headers, Authorization: `${token}` }
      try {
        const response = await fetch(apiApprove, {
          method: 'POST',
          headers,
          body: JSON.stringify({ id: payload._id })
        })
        if (response.ok) {
          const { results } = await response.json()
          dispatch(usersUpdate(payload))
          console.log(results)
        } else {
          console.error('Error with status code:', response.status)
        }
      } catch (error) {
        console.error('Login error:', error)
      }
    }
  }
  return { handleDeleteUser, handleUpdateUserProfile, handleApproveUser }
}
