import React, { useState } from 'react'
import {
  Typography, Button, IconButton,
  Table, TableBody, TableRow, TableHead, TableContainer, TableCell,
  Dialog, DialogActions, DialogContent, DialogTitle,
  Grid, TextField
} from '@mui/material'
import { Add, Edit, DeleteForever } from '@mui/icons-material'
import { Colors } from '../styles/theme'
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import Dropzone from './ImageUpload/Dropzone'
import './Brands.css'
import DeleteConfirmationDialog from './DeleteConfirmationDialog'
import { useAppContext } from '../context/AppProvider'
import { useS3 } from '../hooks/useS3'
import { useSettings } from '../hooks/useSettings'

const validationData = Yup.object().shape({
  title: Yup.string().required('Name is required')

})

const Settings = () => {
  const { state } = useAppContext()
  const settings = state.settings.data
  const [fileForUpload, setFileForUpload] = useState(null)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [settingToDelete, setSettingToDelete] = useState(null)

  const { uploadToS3 } = useS3()
  const { addSettings, deleteSettings, updateSettings } = useSettings()

  const [open, setOpen] = useState(false)
  const [initialValues, setInitialValues] = useState({
    _id: -1,
    type: 'homepageImage',
    title: '',
    img: ''
  })
  const handleAddSetting = (setting) => {
    setInitialValues({
      _id: -1,
      type: 'homepageImage',
      title: '',
      img: ''
    })
    console.log(setting)
    setOpen(true)
  }

  const handleDelete = (setting) => {
    console.log('delete', setting)
    deleteSettings(setting)
  }

  const handleOpenDeleteDialog = (setting) => {
    setSettingToDelete(setting)
    setDeleteDialogOpen(true)
  }

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false)
    setSettingToDelete(null)
  }

  const handleConfirmDelete = () => {
    if (settingToDelete) {
      handleDelete(settingToDelete)
    }
    handleCloseDeleteDialog()
  }
  const handleEdit = (setting) => {
    setInitialValues(setting)
    setOpen(true)
  }

  const handleSubmit = async (values) => {
    if (fileForUpload) {
      try {
        await uploadToS3(fileForUpload)
      } catch (error) {
        console.error('File upload failed:', error)
        // Handle the upload error appropriately
      }
    }

    setOpen(false)
    console.log(values)
    if (values._id === -1) {
      await addSettings(values)
    } else {
      await updateSettings(values)
    }
  }
  return (
    <>
      <Typography variant='h6' sx={{ marginBottom: '20px' }}>Homepage</Typography>
      <Button startIcon={<Add />} variant='outlined' onClick={handleAddSetting} sx={{ color: Colors.fontColor.main, borderColor: Colors.borderColor.main, marginBottom: '20px' }}>Adaugă fotografie nouă</Button>
      <TableContainer>
        <Table>
        <TableHead>
          <TableRow>
            <TableCell>Titlu</TableCell>
            <TableCell>Fotografie</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            {settings.map((homepage, index) =>
            <TableRow key={index}>
              {/* <TableCell>{product._id}</TableCell> */}
              <TableCell sx={{ textTransform: 'capitalize' }}>{homepage.title}</TableCell>
              <TableCell><img src={homepage.img} style={{ height: '30px' }}/></TableCell>
              <TableCell>
                <IconButton onClick={() => handleEdit(homepage)}>
                  <Edit />
                </IconButton>
                <IconButton onClick={() => handleOpenDeleteDialog(homepage)}>
                  <DeleteForever sx={{ color: Colors.accent.main }}/>
                </IconButton>
              </TableCell>
            </TableRow>
            )}
        </TableBody>
        </Table>
      </TableContainer>
      <Dialog
      open={open}
      fullWidth
      maxWidth='lg'
      >
        <DialogTitle>
              {'Adaugă/schimbă fotografia'}
        </DialogTitle>
        <Formik initialValues={initialValues} validationSchema={validationData}
        onSubmit={handleSubmit}>
          {({ setFieldValue, dirty, isValid, getFieldProps }) => (
          <Form>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    name='title'
                    label='Title'
                    fullWidth
                    required
                  />
                  <ErrorMessage name='title'>
                    {(message) => (
                      <Typography color={'red'}>{message}</Typography>
                    )}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={Dropzone}
                    name='img'
                    onFilesChange={(files) => { console.log(files); setFieldValue('img', files.length !== 0 ? files[0].hashedName : ''); setFileForUpload(files.length ? files[0] : null) }}
                    className= 'brands-dragndrop'
                    existingLogoUrl={initialValues.img}
                  />
                  <ErrorMessage name='img'>
                    {(message) => (
                      <Typography color={'red'}>{message}</Typography>
                    )}
                  </ErrorMessage>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
            {
                getFieldProps('_id').value !== -1
                  ? (
                <Button
               disabled={!dirty || !isValid}
               type='submit' variant='contained' color='primary'>Save Edit</Button>
                    )
                  : (
                <Button
               disabled={!dirty || !isValid}
               type='submit' variant='contained' color='primary'>Save</Button>
                    )
               }
              <Button autoFocus variant='contained' onClick={() => setOpen(false)}>Cancel</Button>
            </DialogActions>
          </Form>

          )}
        </Formik>
      </Dialog>
      <DeleteConfirmationDialog
      open={deleteDialogOpen}
      onClose={handleCloseDeleteDialog}
      onConfirm={handleConfirmDelete}
      object={settingToDelete ? settingToDelete.name : ''}
    />
    </>
  )
}

export default Settings
