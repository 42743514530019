import { useState } from 'react'

export const useS3 = () => {
  const [uploading, setUploading] = useState(false)
  const apiUrl = 'https://private-api.primusvest.ro/v1/get-presigned-url'

  const uploadToS3 = async (file) => {
    // console.log(file)
    if (file.file === undefined) { return }
    const token = localStorage.getItem('token')
    let headers = { 'Content-Type': 'application/json' }
    if (token) {
      headers = { ...headers, Authorization: `${token}` }
      setUploading(true)
      try {
        const queryParams = new URLSearchParams({
          fileName: file.hashedName, // Assuming `file.name` is available and you want to send the original file name
          fileType: file.file.type
        })
        const presignedUrlResponse = await fetch(`${apiUrl}?${queryParams.toString()}`, {
          method: 'GET',
          headers
        })

        if (!presignedUrlResponse.ok) {
          throw new Error('Failed to fetch presigned URL')
        }
        const presignedUrlData = await presignedUrlResponse.json()
        const presignedUrl = presignedUrlData.url
        await fetch(presignedUrl, {
          method: 'PUT',
          headers: { 'Content-Type': file.file.type },
          body: file.file
        })

        setUploading(false)
      } catch (error) {
        console.error('Failed to upload to S3', error)
        throw error
      } finally {
        setUploading(false)
      }
    }
  }

  const downloadFromS3 = async (url) => {
    const token = localStorage.getItem('token')
    if (token) {
      try {
        const response = await fetch(url, {
          method: 'GET'
        })

        if (!response.ok) {
          throw new Error('Network response was not ok.')
        }

        const blob = await response.blob()
        const downloadUrl = window.URL.createObjectURL(blob)
        const timestamp = new Date().toISOString().replace(/[:\-T.]/g, '').slice(0, -4)

        const link = document.createElement('a')
        link.href = downloadUrl
        link.setAttribute('download', `export_${timestamp}.csv`) // Set dynamic filename
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
      } catch (error) {
        console.error('Download failed:', error)
      }
    }
  }

  return { uploadToS3, downloadFromS3, uploading }
}
