import React, { useState } from 'react'
import {
  Typography, Button, IconButton,
  Table, TableBody, TableRow, TableHead, TableContainer, TableCell,
  Dialog, DialogActions, DialogContent, DialogTitle,
  Grid, TextField, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel
} from '@mui/material'
import { Edit, DeleteForever } from '@mui/icons-material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { Colors } from '../styles/theme'
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { useAppContext } from '../context/AppProvider'
import { useUsers } from '../hooks/useUsers'
import DeleteConfirmationDialog from './DeleteConfirmationDialog'

const validationData = Yup.object().shape({
  company: Yup.string().required('Nume firmă is required'),
  cui: Yup.string().required('CUI is required'),
  status: Yup.string().required('Status is required'),
  email: Yup.string().required('Email is required'),
  username: Yup.string().required('Username is required')

})

const Users = () => {
  const { state } = useAppContext()
  const { handleUpdateUserProfile, handleDeleteUser, handleApproveUser } = useUsers()
  const users = state.users.data
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [userToDelete, setUserToDelete] = useState(null)

  const [open, setOpen] = useState(false)
  const [initialValues, setIntitalValues] = useState({
    _id: -1,
    name: '',
    description: '',
    price: 0,
    clearence: false,
    package: false
  })

  const handleOpenDeleteDialog = (user) => {
    setUserToDelete(user)
    setDeleteDialogOpen(true)
  }

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false)
    setUserToDelete(null)
  }

  const handleConfirmDelete = () => {
    if (userToDelete) {
      handleDelete(userToDelete)
    }
    handleCloseDeleteDialog()
  }

  const handleDelete = (user) => {
    handleDeleteUser(user._id)
  }

  const handleApprove = (user) => {
    const payload = { ...user, status: 'active' }
    handleApproveUser(payload)
  }

  const handleEdit = (user) => {
    setIntitalValues(user)
    setOpen(true)
  }

  const handleSubmit = (values) => {
    setOpen(false)
    handleUpdateUserProfile(values)
  }
  return (
    <>
      <Typography variant='h6' sx={{ marginBottom: '20px' }}>Utilizatori</Typography>

      <TableContainer>
        <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nume firmă</TableCell>
            <TableCell>CUI</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Username</TableCell>
            <TableCell>Telefon</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Acțiuni</TableCell>
            <TableCell>Aprobare</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            {users.map(user =>
            <TableRow key={user._id}>
              {/* <TableCell>{product._id}</TableCell> */}
              <TableCell>{user.company}</TableCell>
              <TableCell>{user.cui}</TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>{user.username}</TableCell>
              <TableCell>{user.phone}</TableCell>
              <TableCell>{user.status}</TableCell>
              <TableCell>
                <IconButton onClick={() => handleEdit(user)}>
                  <Edit />
                </IconButton>
                <IconButton onClick={() => handleOpenDeleteDialog(user)}>
                  <DeleteForever sx={{ color: Colors.accent.main }}/>
                </IconButton>
              </TableCell>
              <TableCell>
                <IconButton>
                  <CheckCircleOutlineIcon color='success' onClick={() => handleApprove(user)}/>
                </IconButton>
                </TableCell>
            </TableRow>
            )}
        </TableBody>
        </Table>
      </TableContainer>
      <Dialog
      open={open}
      fullWidth
      maxWidth='lg'
      >
        <DialogTitle>
              {'Modifică datele utilizatorului'}
        </DialogTitle>
        <Formik initialValues={initialValues} validationSchema={validationData}
        onSubmit={handleSubmit}>
          {({ dirty, isValid, values, getFieldProps, setFieldValue }) => (
          <Form>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    name='company'
                    label='Nume firmă'
                    required
                    fullWidth
                  />
                  <ErrorMessage name='company'>
                    {(message) => (
                      <Typography color={'red'}>{message}</Typography>
                    )}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    name='cui'
                    label='CUI'
                    required
                    fullWidth
                  />
                  <ErrorMessage name='cui'>
                    {(message) => (
                      <Typography color={'red'}>{message}</Typography>
                    )}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    name='email'
                    label='Email'
                    required
                    fullWidth
                  />
                  <ErrorMessage name='email'>
                    {(message) => (
                      <Typography color={'red'}>{message}</Typography>
                    )}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    name='username'
                    label='Username'
                    required
                    fullWidth
                  />
                  <ErrorMessage name='username'>
                    {(message) => (
                      <Typography color={'red'}>{message}</Typography>
                    )}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    name='phone'
                    label='Nr. de telefon'
                    fullWidth
                  />
                  <ErrorMessage name='phone'>
                    {(message) => (
                      <Typography color={'red'}>{message}</Typography>
                    )}
                  </ErrorMessage>
                </Grid>
                {/* <Grid item xs={12}>
                  <Field
                    as={TextField}
                    name='status'
                    label='Status'
                    required
                    fullWidth
                  />
                  <ErrorMessage name='status'>
                    {(message) => (
                      <Typography color={'red'}>{message}</Typography>
                    )}
                  </ErrorMessage>
                </Grid> */}
                <Grid item xs={12}>
                  <Field
                  as={FormControl}
                  sx={{ border: 'solid 1px', borderColor: Colors.borderColor.main, borderRadius: '5px', padding: '10px' }}
                  fullWidth
                  >
                    <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label" sx={{ fontSize: '12px' }}>Status</FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={values.status}
                          onChange={(event) => { console.log(event.target.value); setFieldValue('status', event.target.value) }}
                        >
                          <FormControlLabel value="active" control={<Radio color='success' />} label="Active" />
                          <FormControlLabel value="pending" disabled control={<Radio color='secondary' />} label="Pending" />
                          <FormControlLabel value="inactive" control={<Radio color='accent' />} label="Inactive" />
                        </RadioGroup>
                      </FormControl>
                  </Field>

                </Grid>

              </Grid>
            </DialogContent>
            <DialogActions>
              {
                getFieldProps('_id').value !== -1
                  ? (
                <Button
               disabled={!dirty || !isValid}
               type='submit' variant='contained' color='primary'>Save Edit</Button>
                    )
                  : (
                <Button
               disabled={!dirty || !isValid}
               type='submit' variant='contained' color='primary'>Save</Button>
                    )
               }
              <Button autoFocus variant='contained' onClick={() => setOpen(false)}>Cancel</Button>
            </DialogActions>
          </Form>

          )}
        </Formik>
      </Dialog>
      <DeleteConfirmationDialog
      open={deleteDialogOpen}
      onClose={handleCloseDeleteDialog}
      onConfirm={handleConfirmDelete}
      object={userToDelete ? userToDelete.username : ''}
    />
    </>
  )
}

export default Users
