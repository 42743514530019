import PropTypes from 'prop-types'
import { initialState, rootReducer } from '../reducers'
import React, { createContext, useReducer, useContext } from 'react'

export const AppContext = createContext()

export const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(rootReducer, initialState)

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  )
}

AppProvider.propTypes = {
  children: PropTypes.node.isRequired
}
// Hook that components can use to access the context
export const useAppContext = () => useContext(AppContext)
