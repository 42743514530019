import { ITEMS_FETCH, ITEMS_FAILED, ITEMS_LOADING, ADD_ITEMS, UPDATE_ITEMS, DELETE_ITEMS } from '../actions/itemsActions'

export const initialItemsState = { loading: false, data: [], total: 0, error: null }

export const itemsReducer = (state = initialItemsState, action) => {
  switch (action.type) {
    case ITEMS_LOADING:
      return { ...state, loading: true }
    case ITEMS_FETCH:
      return { ...state, loading: false, data: action.payload.data, total: action.payload.total }
    case ITEMS_FAILED:
      return { ...state, loading: false, data: [], error: action.payload, total: 0 }

    case ADD_ITEMS:
      return {
        ...state,
        data: [...state.data, action.payload],
        total: state.total + 1
      }

    case UPDATE_ITEMS:
      return {
        ...state,
        data: state.data.map(item =>
          item._id === action.payload._id ? { ...item, ...action.payload } : item
        )
      }

    case DELETE_ITEMS:
      return {
        ...state,
        data: state.data.filter(item => item._id !== action.payload),
        total: state.total - 1
      }

    default:
      return state
  }
}
